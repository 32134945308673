import * as yup from 'yup';
import { Actions } from './index';
import { Field } from 'signer-app/types/editor-types';
import type { TemplateResponse } from 'hello-react/web-app-client/namespace/prep-and-send';
import { BANNER } from 'hellospa/components/prep-and-send/formik-utils';
import {
  FileStatus as FilePollResponseStatus,
  FileType,
  SignerFileType,
} from 'js/sign-components/generated/types/HelloRequest';

const UserFileTypes = { ...FileType, ...SignerFileType };
export { FilePollResponseStatus, UserFileTypes };
export interface FileUploadResponse {
  name: string;
  rootSnapshotGuid: string;
  draftSnapshotGuid?: string;
  guid?: string;
  pwRequired?: boolean;
  templateGuid?: string;
  fields?: Array<Field>;
  // File type
  type?: FileType;
  externalFileCacheKey?: ExternalFileDownloadResponse['cacheKey'];
  externalServiceType?: string;
  documentGuid?: string;
}

export const fileUploadResponseSchema = yup.object<FileUploadResponse>({
  name: yup.string().required(),
  rootSnapshotGuid: yup.string().required(),
  pwRequired: yup.bool().default(false),
  templateGuid: yup.string(),
  fields: yup.array(),
  type: yup.mixed<FileType>().notRequired(),
  externalFileCacheKey: yup.string(),
  externalServiceType: yup.string(),
  documentGuid: yup.string().notRequired(),
});

export function isErrorStatus(status: FilePollResponseStatus): boolean {
  switch (status) {
    case FilePollResponseStatus.Converting:
    case FilePollResponseStatus.Ok:
    case FilePollResponseStatus.FileDownloaded:
    case FilePollResponseStatus.Downloading:
    case FilePollResponseStatus.DownloadQueued:
    case FilePollResponseStatus.Deleted:
      return false;
    default:
      return true;
  }
}

// this is the signature coming from attachment/delete
export interface FileDeleteResponse {
  guid: string;
  parentGuid: string;
}

// naming it similar (plural) to the above so we can use it later
// this is the signature coming from prep-and-send/delete-files
export interface FilesDeleteResponse {
  success: boolean;
  error?: string;
}

export interface FileReorderResponse {
  success: boolean;
}
export interface FileSetPasswordResponse {
  success: boolean;
}

export interface FilePollResponsePending {
  status: FilePollResponseStatus.Converting;
  progress: number;
  total: number;
}

export interface FilePollResponseDeleted {
  status: FilePollResponseStatus.Deleted;
}

export interface FilePollReplaceData {
  replaceSnapshotGuid: string;
  replaceParentSnapshotId: string;
  replaceDocumentId: string;
  replaceDocumentName: string;
  documentId: string;
  documentName: string;
}

export interface FilePollResponseDone {
  status: FilePollResponseStatus.Ok;
  pageCount: number;
  guid: string;
  tsmGroupGuid: string;
  hasOverlayData: boolean;
  isLandscape: boolean;
  replaceData?: FilePollReplaceData | null;
  documentGuid?: string;
}

interface FilePollResponseError {
  status: FilePollResponseStatus.Error;
  description?: string;
}

interface FilePollResponseError404 {
  status: FilePollResponseStatus.Error404;
  description?: string;
}

interface FilePollResponsePasswordRequired {
  status: FilePollResponseStatus.PasswordRequired;
}

interface FilePollResponseTooManyPages {
  status: FilePollResponseStatus.TooManyPages;
  maxPages: number;
}

interface FilePollResponseTooLarge {
  status: FilePollResponseStatus.FileTooLarge;
}

// External (integration) file responses
interface ExternalFileProgressResponse {
  isImport?: boolean;
  serviceType?: string;
}

interface ExternalFilePollResponseDownloading {
  status: FilePollResponseStatus.Downloading;
}

interface ExternalFilePollResponseDownloadQueued
  extends ExternalFileProgressResponse {
  status: FilePollResponseStatus.DownloadQueued;
  cacheKey: string;
}

interface ExternalFilePollResponseFileTooLarge
  extends ExternalFileProgressResponse {
  status: FilePollResponseStatus.FileTooLarge;
}

interface ExternalFilePollResponseRetrieveError
  extends ExternalFileProgressResponse {
  status: FilePollResponseStatus.RetrieveError;
}

interface ExternalFilePollResponseBadAuth extends ExternalFileProgressResponse {
  status: FilePollResponseStatus.BadAuth;
  authUrl: string;
}

interface ExternalFilePollResponseBadRequest
  extends ExternalFileProgressResponse {
  status: FilePollResponseStatus.BadRequest;
}

export interface ExternalFileDownloadResponse {
  status: FilePollResponseStatus.DownloadQueued;
  cacheKey: string;
}

export const externalFileDownloadStatusSchema = yup.object({
  status: yup.mixed<FilePollResponseStatus.FileDownloaded>(),
  isImport: yup.boolean().required(),
  serviceType: yup.string().required(),
  name: yup.string().required(),
  rootSnapshotGuid: yup.string().required(),
  rootSnapshotIsAccessible: yup.boolean().required(),
});

type ExternalFileDownloadStatusResponse = yup.InferType<
  typeof externalFileDownloadStatusSchema
>;

// captures all the responses related to file uploads, including regular
// uploads and uploads from different integrations
export type FilePollResponse =
  | FilePollResponsePending
  | FilePollResponseDone
  | FilePollResponseDeleted
  | FilePollResponsePasswordRequired
  | FilePollResponseTooManyPages
  | FilePollResponseTooLarge
  | FilePollResponseError
  | FilePollResponseError404
  // external file progress
  | ExternalFileDownloadStatusResponse
  | ExternalFilePollResponseDownloading
  | ExternalFilePollResponseDownloadQueued
  | ExternalFilePollResponseFileTooLarge
  | ExternalFilePollResponseRetrieveError
  | ExternalFilePollResponseBadAuth
  | ExternalFilePollResponseBadRequest;

export type FieldsKeyed = Partial<Record<Field['id'], Field>>;

export type UserFileBase = {
  readonly name: string;
  readonly type: FileType;
  readonly rootSnapshotGuid: string;
  readonly pwRequired: boolean;
  readonly documentGuid?: string;
};

export type UserFileConverting = UserFileBase & {
  readonly status: FilePollResponseStatus;
  readonly order: number;

  // HACK: UserFileKeyed uses `Omit<UserFile`. When Omit is run on a union
  // (UserFile) it only keeps the keys that are common to ALL TYPES in the
  // union. So my hack here is to claim that this might contain these
  // properties, but they're `undefined`.
  readonly guid?: string;
  readonly draftSnapshotGuid?: string;
  readonly tsmGroupGuid?: string;
  readonly pageCount?: number;
  readonly templateGuid?: string; // this will be set for template files

  // This is really metadata and should probably be somewhere else
  readonly progress?: number;
  readonly total?: number;
  readonly replaceData?: FilePollReplaceData | null | undefined;

  readonly fields: Field[];
};

type UserFileOk = UserFileBase & {
  readonly status: FilePollResponseStatus.Ok;
  readonly order: number;

  readonly guid: string;
  readonly tsmGroupGuid: string;
  readonly pageCount: number;
  readonly templateGuid?: string; // this will be set for template files
  readonly draftSnapshotGuid?: string; // For request signature flows
  // This is really metadata and should probably be somewhere else
  readonly progress?: number;
  readonly total?: number;
  readonly replaceData?: FilePollReplaceData | null | undefined;

  readonly fields: Field[];
};

export type SignerFile = {
  readonly status: FilePollResponseStatus;
  readonly name: string;
  readonly type: SignerFileType.Signer;
  readonly progress?: number;
  readonly error?: string;
};

export type UserFile = UserFileConverting | UserFileOk;

export type MergeField = {
  readonly field: Field;
  readonly error: null | 'overflow';
  readonly fileIds: UserFile['rootSnapshotGuid'][];
};

export interface FieldCollection {
  [key: string]: MergeField;
}

export type UserFileKeyed = Omit<UserFile, 'fields'> & {
  readonly fields: FieldsKeyed;
};

export type UserFilesKeyed = Partial<
  Record<UserFile['rootSnapshotGuid'], UserFileKeyed>
>;

export const fieldSchema = yup.lazy((value) => {
  const field = value as Field;
  switch (field.type) {
    case 'text':
      return yup.object().shape({
        value: yup.string().trim(),
      });
    default:
      return yup.object();
  }
});

export const userFileSchema = yup.object<UserFile>().shape({
  name: yup.string().trim().required(),
  type: yup
    .mixed<FileType>()
    .oneOf([FileType.Upload, FileType.Template, FileType.External])
    .required(),
  rootSnapshotGuid: yup.string().trim().required(),
  order: yup.number(),
  guid: yup.string().trim(),
  tsmGroupGuid: yup.string().trim(),
  status: yup.mixed().default('converting').oneOf(['ok']),
  pageCount: yup.number(),
  fields: yup.array().of(fieldSchema).nullable().default({}),
  progress: yup.number(),
  total: yup.number(),
  pwRequired: yup.boolean().default(false),
});

export const signerFileSchema = yup.object<SignerFile>().shape({
  name: yup.string().trim().required(),
  type: yup.mixed<SignerFileType>().oneOf([SignerFileType.Signer]).required(),
  status: yup.mixed().default('converting').oneOf(['ok']).required(),
  progress: yup.number(),
  error: yup
    .string()
    .test('is-error-free', '', function isErrorFree(value: string) {
      if (value) {
        return this.createError({
          path: `[${this.path}.${BANNER}]`,
          message: value,
        });
      }
      return true;
    }),
});

export const multiUserFileSchema = yup.object().shape({
  files: yup.array().of(userFileSchema).min(1).required(),
});

export interface ExternalFile {
  status: string;
  serviceType: string;
  name: string;
  rootSnapshotGuid: string;
  rootSnapshotIsAccessible: boolean;
  cacheKey: string;
  fileReference: string;
}

export interface CreateFileAction {
  type: Actions.CreateFile;
  payload: UserFile;
}

export interface ConvertFileAction {
  type: Actions.ConvertFile;
  payload: UserFile;
}

export interface UpdateFileAction {
  type: Actions.UpdateFile;
  payload: UserFile;
}

export interface DeleteFileAction {
  type: Actions.DeleteFile;
  payload: UserFile['rootSnapshotGuid'];
}

export interface UploadFileAction {
  type: Actions.UploadFile;
  payload: File;
}

export interface UpdateFieldAction {
  type: Actions.UpdateField;
  payload: MergeField;
}

export interface TemplateFileAction {
  type: Actions.UseTemplate;
  payload: TemplateResponse;
}

export interface DeleteTemplateAction {
  type: Actions.DeleteTemplate;
  payload: TemplateResponse['templateGuid'];
}

export interface ExternalFileAction {
  type: Actions.ExternalFile;
  payload: ExternalFile;
}

export interface ReorderFileAction {
  type: Actions.ReorderFile;
  payload: {
    id: UserFile['rootSnapshotGuid'];
    newOrder: number;
    oldOrder: number;
  };
}

export type FileActions =
  | CreateFileAction
  | ConvertFileAction
  | UpdateFileAction
  | DeleteFileAction
  | UploadFileAction
  | UpdateFieldAction
  | TemplateFileAction
  | DeleteTemplateAction
  | ExternalFileAction
  | ReorderFileAction;
