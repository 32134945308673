import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import * as Yup from 'yup';
import * as Sentry from '@sentry/browser';
import {
  appendQueryParameters,
  queryParams,
} from 'js/sign-components/common/fetch';
import { loadDataWithFallback } from 'hello-react/web-app-client/utils';
import {
  ExternalFileDownloadResponse,
  FilePollResponse,
  FilePollResponseStatus,
  FileReorderResponse,
  FilesDeleteResponse,
  FileSetPasswordResponse,
  FileUploadResponse,
  fileUploadResponseSchema,
  UserFile,
  UserFilesKeyed,
  UserFileTypes,
} from 'hellospa/page/prep-and-send/data/types/file';
import {
  embeddedDataSchema,
  EmbeddedPollResponse,
} from 'hellospa/page/prep-and-send/data/types/embedded';
import type { CoverPage } from 'hellospa/page/prep-and-send/data/types/coverPage';
import type { Document } from 'hellospa/page/prep-and-send/data/types/document';
import type {
  ExternalFolderResponse,
  ExternalServiceAuthStatusResponse,
  UploadIntegrations,
  UploadIntegrationsResponse,
} from 'hellospa/page/prep-and-send/data/types/integration';
import type {
  RequestTypes,
  EditorFields,
  Contact,
} from 'hellospa/page/prep-and-send/data/types';
import type { TemplateCreateStatus } from 'hellospa/page/prep-and-send/data/types/meta';
import type { User } from 'hellospa/page/prep-and-send/data/types/user';
import { CC, ccSchema } from 'hellospa/page/prep-and-send/data/types/cc';
import {
  ExampleFields,
  BulkSendInitResponse,
  bulkSendDataSchema,
} from 'hellospa/page/prep-and-send/data/types/bulk-send';
import {
  Flags,
  flagsSchema,
  requestTypesSchema,
} from 'hellospa/page/prep-and-send/data/types/flags';
import {
  Recipient,
  recipientSchema,
  RecipientTypes,
  Signer,
} from 'hellospa/page/prep-and-send/data/types/recipient';
import { apiAppSchema } from 'hellospa/page/prep-and-send/data/types/api-app';
import {
  settingsSchema,
  UpdateAccountResponse,
} from 'hellospa/page/prep-and-send/data/types/settings';
import type { Rule } from 'signer-app/conditional-logic/types';
import { lazyUnion, InferUnion } from 'hellospa/common/utils/yup';
import { notEmpty, unreachable } from 'js/sign-components/common/ts-utils';
import {
  Page,
  AutofillFieldTypes,
  Field,
  FieldIntegrationMetadata,
  fontFamilies,
  signatureColors,
} from 'signer-app/types/editor-types';
import {
  validatePrepAndSendRequest,
  validateTemplate,
} from 'js/sign-components/generated/validators/validateHelloRequest';
import { assertDataIsValid } from 'signer-app/utils/ajv-validation';
import {
  convertForPrepAndSend,
  convertForHelloRequest,
  convertTemplates,
} from './prep-and-send-converters';
import { cloneDeep } from 'lodash';
import { validationTypes } from 'signer-app/signer-experience/signer-validation-constants';
import { redirectTo } from 'signer-app/utils/redirect';

export type ImportContactsStatusResponse = {
  success: boolean;
  complete: boolean;
  progress: number;
  count: number;
};

export type TemplateResponse = {
  guid: string;

  requestType: RequestTypes;
  recipientReassignment: boolean;
  recipientOrder: boolean;

  dateFormat: string;
  jsDateFormat: string;

  templateGuid: string;

  files: FileUploadResponse[];
  editorFields: EditorFields;
  recipients: Recipient[];
  ccs: CC[];
  document: Document;
};

const pageSchema = Yup.object<Page>({
  src: Yup.string().required(),
  width: Yup.number().required(),
  height: Yup.number().required(),
  documentId: Yup.string(),
  orientation: Yup.mixed().oneOf([0, 1]),
});

const ruleSchema = Yup.object<Rule>({
  id: Yup.string().required(),
  triggerOperator: Yup.mixed().oneOf(['AND', 'OR']),
  triggers: Yup.array(),
  actions: Yup.array(),
});

const phpBaseField = {
  id: Yup.string().required(),
  invalidApiId: Yup.string().notRequired(),
  pageIndex: Yup.number().required(),
  name: Yup.string().notRequired(),
  x: Yup.number().required(),
  y: Yup.number().required(),
  required: Yup.bool().notRequired(),
  originalRequired: Yup.bool().notRequired(),
  signer: Yup.string().required(),
  width: Yup.number().required(),
  height: Yup.number().required(),
  documentId: Yup.string().required(),
  hidden: Yup.boolean().notRequired(),
  readOnly: Yup.boolean().notRequired(),
  linkId: Yup.string().nullable().notRequired(),
  // From Integration use to reference with
  // external platform's field type/name
  integrationMetadata: Yup.object<FieldIntegrationMetadata>({
    externalName: Yup.string(),
    externalType: Yup.string(),
  })
    .nullable()
    .notRequired(),
};

const phpFieldStatus = {
  checkbox: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'checkbox'>(),
    checked: Yup.boolean().notRequired().nullable(),
    group: Yup.string().nullable().notRequired(),
    requirement: Yup.string().nullable().notRequired(),
    groupLabel: Yup.string().nullable().notRequired(),
    isEditableMergeField: Yup.boolean().notRequired(),
  }),
  date: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'date'>(),
    placeholder: Yup.string().notRequired(),
    dateFormat: Yup.string().notRequired(),
    fontFamily: Yup.mixed().oneOf([...fontFamilies]),
    fontSize: Yup.number().notRequired(),
    value: Yup.string().notRequired().nullable(),
  }),
  initials: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'initials'>(),
    signatureColors: Yup.mixed().oneOf([...signatureColors]),
    signature: Yup.object({
      guid: Yup.string(),
    })
      .notRequired()
      .default(undefined),
  }),
  signature: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'signature'>(),
    signatureColors: Yup.mixed().oneOf([...signatureColors]),
    signature: Yup.object({
      guid: Yup.string(),
    })
      .notRequired()
      .default(undefined),
  }),
  text: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'text'>(),
    placeholder: Yup.string().notRequired(),
    lineHeight: Yup.number().notRequired(),
    fontFamily: Yup.mixed().oneOf([...fontFamilies]),
    fontSize: Yup.number().notRequired(),
    value: Yup.string().notRequired().nullable(),
    masked: Yup.bool().notRequired(),
    isEditableMergeField: Yup.boolean().notRequired(),
    originalFontSize: Yup.number().notRequired(),
    lines: Yup.string().notRequired(),
    autoFillType: Yup.mixed().oneOf(Object.values(AutofillFieldTypes)),
    validationType: Yup.mixed()
      .transform((value) =>
        Object.values(validationTypes).includes(value) ? value : undefined,
      )
      .oneOf(Object.values(validationTypes))
      .notRequired(),
    validationCustomRegex: Yup.string().notRequired(),
    validationCustomRegexFormatLabel: Yup.string().notRequired(),
  }),
  dropdown: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'dropdown'>(),
    options: Yup.array().of(Yup.string()),
    value: Yup.string().notRequired().nullable(),
    fontFamily: Yup.mixed().oneOf([...fontFamilies]),
    fontSize: Yup.number().notRequired(),
  }),
  radiobutton: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'radiobutton'>(),
    checked: Yup.boolean().required(),
    group: Yup.string().required(),
    requirement: Yup.string().nullable().required(),
    groupLabel: Yup.string().nullable().notRequired(),
  }),
  hyperlink: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'hyperlink'>(),
    lineHeight: Yup.number().notRequired(),
    fontFamily: Yup.mixed().oneOf([...fontFamilies]),
    fontSize: Yup.number().notRequired(),
    value: Yup.string().required().nullable(),
    url: Yup.string().required(),
  }),
  image: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'image'>(),
    src: Yup.string(),
    scale: Yup.boolean(),
  }),
  rectangle: Yup.object<Field>({
    ...phpBaseField,
    type: Yup.mixed<'rectangle'>(),
    filled: Yup.boolean(),
  }),
};

const fieldSchema = lazyUnion<Field, 'type'>('type', phpFieldStatus);

export const templateResponse = Yup.object<TemplateResponse>({
  guid: Yup.string().required(),

  requestType: requestTypesSchema,
  recipientReassignment: Yup.bool().required(),
  recipientOrder: Yup.bool().required(),

  dateFormat: Yup.string().required(),
  jsDateFormat: Yup.string().required(),

  templateGuid: Yup.string().required(),
  files: Yup.array().of(fileUploadResponseSchema),
  editorFields: Yup.object({
    pages: Yup.array().of(pageSchema),
    fields: Yup.array().of(fieldSchema),
    pdfFields: Yup.array().of(fieldSchema), // should be empty in case of templates
    rules: Yup.array().of(ruleSchema),
    initialPageRotation: Yup.number().default(0),
  }),
  recipients: Yup.array().of(recipientSchema),
  ccs: Yup.array().of(ccSchema),
  document: Yup.object({
    title: Yup.string().ensure(),
    message: Yup.string().ensure(),
    expiresAt: Yup.date().nullable().notRequired(),
  }).required(),
});

export const dataSchema = Yup.object({
  // The save endpoint sends this data object, so if you have data the user
  // can't change, it doesn't belong here.
  data: Yup.object({
    guid: Yup.string().required(),
    settings: settingsSchema,
    files: Yup.array().of(fileUploadResponseSchema),
    editorFields: Yup.object({
      pages: Yup.array().of(pageSchema),
      fields: Yup.array().of(fieldSchema),
      pdfFields: Yup.array().of(fieldSchema),
      rules: Yup.array().of(ruleSchema),
      initialPageRotation: Yup.number().default(0),
    }),
    recipients: Yup.array().of(recipientSchema).ensure(),
    ccs: Yup.array().of(ccSchema).ensure(),
    document: Yup.object({
      title: Yup.string().ensure(),
      message: Yup.string().ensure(),
      expiresAt: Yup.date().notRequired().nullable(),
    }).required(),
    templates: Yup.array().of(templateResponse),

    coverPage: Yup.object<CoverPage>({
      from: Yup.string().ensure(),
      to: Yup.string().ensure(),
      message: Yup.string().ensure(),
    }),
    bulkSend: bulkSendDataSchema,
    shouldConvertDocToTemplate: Yup.boolean().notRequired(),
    isMeOnly: Yup.boolean().notRequired(),
  }),

  flags: flagsSchema,
  editorFeatures: Yup.array().of(Yup.string()),
  user: Yup.object({
    id: Yup.number().notRequired(),
    name: Yup.string().ensure(),
    email: Yup.string().email().required(),
    isPaidAccount: Yup.boolean(),
    accountDateFormat: Yup.string(),
    apiIdsEnabled: Yup.boolean(),
    isConfirmedAccount: Yup.boolean(),
    dbxUserId: Yup.string().notRequired().nullable(),
  }).required(),

  apiApp: apiAppSchema,
  embeddedData: embeddedDataSchema,
});
export type DataSchema = Yup.InferType<typeof dataSchema>;

export type TemplateErrorResponse = {
  error: string;
};
export const templateErrorResponse = Yup.object<TemplateErrorResponse>({
  error: Yup.string().required(),
});

const sendRequestErrorSchema = Yup.object({
  message: Yup.string(),
});

const sendRequestUiResponseSchema = Yup.object({
  redirectUrl: Yup.string().notRequired().trim(),
  templateGuid: Yup.string().notRequired().trim(),
  status: Yup.string().notRequired().trim(),
  errorMsg: Yup.string().notRequired().trim(),
  code: Yup.string().notRequired().trim(),
  error: Yup.string().notRequired().trim(),
  errors: Yup.array().of(sendRequestErrorSchema).notRequired(),
  success: Yup.bool().notRequired(),

  needsCharge: Yup.bool().notRequired(),
  chargeType: Yup.string().notRequired(),
  pricePerFaxInCents: Yup.number().notRequired(),
  totalPriceInCents: Yup.number().notRequired(),
  freeFaxPagesLeft: Yup.number().notRequired(),
  faxPageCurrentCount: Yup.number().notRequired(),
  overageFaxMaxLimitInCents: Yup.number().notRequired(),
  initialPageLimit: Yup.number().notRequired(),
  overagePriceInCentsPerPage: Yup.number().notRequired(),
  internationalMultiplier: Yup.number().notRequired(),

  isFirstFax: Yup.bool().notRequired(),
  isAtFaxLimit: Yup.bool().notRequired(),
  templateConversionCandidateId: Yup.string().notRequired(),
  shouldTemplateCreateShow: Yup.boolean().notRequired(),
  simplifyDocToTemplateModal: Yup.boolean().notRequired(),
  shouldEnableGdriveModal: Yup.boolean().notRequired(),
  shouldEnableDropboxModal: Yup.boolean().notRequired(),
  shouldEnableEditAndResendModal: Yup.boolean().notRequired(),
  isEditAndResend: Yup.boolean().notRequired(),
});

const sendEmbeddedRequestResponseSchema = Yup.object({
  success: Yup.bool().required(),
  signature_request_id: Yup.string().required(),
  signature_request_info: Yup.object({
    title: Yup.string(),
    message: Yup.string(),
    expiresAt: Yup.date().nullable().notRequired(),
    signatures: Yup.array().of(
      Yup.object({
        signer_name: Yup.string(),
        signer_email_address: Yup.string(),
        order: Yup.number().nullable(),
      }),
    ),
    cc_email_addresses: Yup.array().of(Yup.string()),
  }),
});

const sendEmbeddedTemplateResponseSchema = Yup.object({
  success: Yup.bool().required(),
  template_id: Yup.string().required(),
  template_status_id: Yup.string().required(),
  template_info: Yup.object({
    title: Yup.string(),
    message: Yup.string(),
    signer_roles: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        order: Yup.number().nullable(),
      }),
    ),
    cc_roles: Yup.array().of(Yup.string()),
  }),
});

export type SendRequestUiResponse = Yup.InferType<
  typeof sendRequestUiResponseSchema
>;
export type SendRequestEmbeddedRequestResponse = Yup.InferType<
  typeof sendEmbeddedRequestResponseSchema
>;
export type SendRequestEmbeddedTemplateResponse = Yup.InferType<
  typeof sendEmbeddedTemplateResponseSchema
>;

export type SendRequestResponse =
  | SendRequestUiResponse
  | SendRequestEmbeddedRequestResponse
  | SendRequestEmbeddedTemplateResponse;

const selfSaveRequestErrorSchema = Yup.object({
  message: Yup.string(),
});

const selfSaveRequestResponseSchema = Yup.object({
  redirectUrl: Yup.string().notRequired().trim(),
  errors: Yup.array().of(selfSaveRequestErrorSchema).notRequired(),
  success: Yup.bool().notRequired(),
  code: Yup.string().notRequired().trim(),
  error: Yup.string().notRequired().trim(),
  needsCharge: Yup.bool().notRequired(),
  signatureRequestId: Yup.string().notRequired().trim(),
  isGmailAddon: Yup.bool().notRequired(),
});

export type SelfSaveRequestResponse = Yup.InferType<
  typeof selfSaveRequestResponseSchema
>;

export type SaveDataType = DataSchema['data'] & {
  requestType: RequestTypes;
};

export const getUploadIntegrations =
  async (): Promise<UploadIntegrationsResponse> => {
    const response = await hsFetch('/prep-and-send/upload-integrations');
    const data = await response.json();
    return {
      uploadIntegrations: data.uploadIntegrations,
      allowIntegrationsUpload: data.allowIntegrationsUpload,
    };
  };

export const preloadTransmissionGroupGuid = async (): Promise<string> => {
  const response = await hsFetch('/prep-and-send');
  const data = await response.json();
  const guid = data.guid || null;
  if (!guid) {
    throw new Error(
      'An error occurred initializing a new prep and send transmission guid',
    );
  }
  return guid;
};

export const getPrepAndSendData = async (
  transmissionGroupGuid: string,
): Promise<DataSchema> => {
  const response = await hsFetch(
    `/prep-and-send/data/${transmissionGroupGuid}`,
  );
  if (response.status === 401) {
    // Request has expired, user should reinstantiate the request
    redirectTo('/home/manage');
  } else if (response.status !== 200) {
    throw new Error('An error occured while getting P&S data');
  }
  const data = await response.json();
  if (data.error) {
    throw new Error(data.error);
  }
  return data;
};

async function _getUnifiedPrepAndSend(
  transmissionGroupGuid: string,
): Promise<DataSchema> {
  const url = `/prep-and-send/unifiedData/${transmissionGroupGuid}`;
  const response = await hsFetch(url);
  if (response.status === 401) {
    // Request has expired, user should reinstantiate the request
    redirectTo('/home/manage');
  } else if (response.status !== 200) {
    throw new Error(
      'An error occured while getting P&S data in Unified JSON format',
    );
  }
  const unifiedData: unknown = await response.json();
  // The return type of `validate` is `asserts data is T`. TypeScript knows that
  // if this function doesn't throw, then unifiedData must be a PrepAndSendRequest
  // because the first parameter is a ValidateFunction<T>
  assertDataIsValid(validatePrepAndSendRequest, unifiedData);

  const data = convertForPrepAndSend(unifiedData);

  return data;
}

function normalizeCCs(oldCCs: CC[], newCCs: CC[]): CC[] {
  const updatedCCs: CC[] = [];
  oldCCs.forEach((cc: CC, index: number) => {
    const newCC: CC = newCCs[index];
    if (
      (cc.type === 'ccEmail' &&
        newCC.type === 'ccEmail' &&
        cc.email === newCC.email) ||
      (cc.type === 'ccRole' &&
        newCC.type === 'ccRole' &&
        cc.name === newCC.name)
    ) {
      updatedCCs.push(newCC);
    }
  });
  return updatedCCs;
}

function normalizeFields(
  oldFields: Field[],
  newFields: Field[],
  areMergeFields: boolean,
): Field[] {
  if (oldFields.length === newFields.length) {
    const updatedFields: Field[] = [];
    oldFields.forEach((field: Field, index: number) => {
      const newField = newFields[index];
      let additionalProperties: any = {};
      if (field.type === 'checkbox' && newField.type === 'checkbox') {
        additionalProperties = {
          checked: field.checked ?? newField.checked,
        };
      } else if (field.type === 'text' && newField.type === 'text') {
        additionalProperties = {
          fontSize: newField.fontSize,
          fontFamily: newField.fontFamily,
        };
      } else if (field.type === 'date' && newField.type === 'date') {
        additionalProperties = {
          dateFormat: field.dateFormat ?? newField.dateFormat,
        };
      } else if (
        (field.type === 'signature' && newField.type === 'signature') ||
        (field.type === 'initials' && newField.type === 'initials')
      ) {
        // Legacy endpoint doesn't set signature type
        additionalProperties = {
          signature: newField.signature
            ? {
                ...newField.signature,
              }
            : undefined,
        };
      } else if (
        field.type === 'text' &&
        newField.type === 'date' &&
        field.signer === 'preparer'
      ) {
        // Me_now date fields show up as text fields
        // in the legacy endpoint
        additionalProperties = {
          type: 'date',
          dateFormat: newField.dateFormat,
        };
      }
      const updatedField = {
        ...field,
        ...additionalProperties,
        pageIndex: field.pageIndex ?? newField.pageIndex,
        documentId: field.documentId ?? newField.documentId,
        readOnly: field.readOnly ?? newField.readOnly,
        required: field.required ?? newField.required,
        // The legacy endpoint doesn't bother to calculate the correct x and y values
        // for merge fields because Prep&Send doesn't really use it, so I'm replacing
        // it with the new endpoint value so that it doesn't fail the comparison.
        x: areMergeFields ? newField.x : field.x,
        y: areMergeFields ? newField.y : field.y,
      };
      updatedFields.push(updatedField);
    });
    return updatedFields;
  } else if (areMergeFields) {
    // Merge fields don't show up in legacy endpoint data
    // while creating a template
    return newFields;
  }
  return oldFields;
}

function normalizeFiles(
  oldFiles: FileUploadResponse[],
  newFiles: FileUploadResponse[],
): FileUploadResponse[] {
  if (oldFiles.length === newFiles.length) {
    const updatedFiles: FileUploadResponse[] = [];
    oldFiles.forEach((oldFile: FileUploadResponse, index: number) => {
      const newFile = newFiles[index];
      const updatedFile = {
        ...oldFile,
        documentGuid: oldFile.documentGuid ?? newFile.documentGuid,
        fields: normalizeFields(
          oldFile.fields ?? [],
          newFile.fields ?? [],
          true,
        ),
      };
      updatedFiles.push(updatedFile);
    });
    return updatedFiles;
  }
  return oldFiles;
}

type NewRecipientType = Recipient &
  Partial<{
    order: number;
  }>;

function normalizeRecipients(
  oldRecipients: Recipient[],
  newRecipients: NewRecipientType[],
  fromTemplate: boolean = false,
): NewRecipientType[] {
  if (oldRecipients.length === newRecipients.length) {
    const updatedRecipients: NewRecipientType[] = [];
    oldRecipients.forEach((recipient: Recipient, index: number) => {
      const newRecipient = newRecipients[index];
      let updatedRecipient: NewRecipientType;
      if (recipient.type === 'signer') {
        const role = recipient.role ?? (newRecipient as Signer).role;
        updatedRecipient = {
          ...recipient,
          role,
          order: newRecipient.order,
        };
        if (newRecipient.type === 'role' && fromTemplate) {
          updatedRecipient = {
            ...newRecipient,
          };
        }
      } else {
        updatedRecipient = {
          ...recipient,
          order: newRecipient.order,
        };
      }
      updatedRecipients.push(updatedRecipient);
    });
    return updatedRecipients;
  } else if (fromTemplate) {
    // When multiple templates with different roles are added together,
    // template recipients includes signer info that was applied to roles
    // from other templates in the request in the legacy response, which
    // is wrong. The Unified JSON endpoint correctly provides just the roles
    // in that template, which is what we want.
    const updatedRecipients = newRecipients.map((recipient) => ({
      ...recipient,
    }));
    return updatedRecipients;
  }
  return oldRecipients;
}

type UpdatedTemplateResponse = TemplateResponse &
  Partial<{
    title: string;
    message: string;
    isOwner: boolean;
  }>;

function normalizeTemplate(
  data: [
    TemplateResponse | TemplateErrorResponse,
    TemplateResponse | TemplateErrorResponse,
  ],
): [
  TemplateResponse | TemplateErrorResponse,
  TemplateResponse | TemplateErrorResponse,
] {
  const [newTemplate, oldTemplate] = data;
  if ('error' in newTemplate || 'error' in oldTemplate) {
    return [newTemplate, oldTemplate];
  }
  const updatedTemplate = {
    ...oldTemplate,
    files: normalizeFiles(oldTemplate.files ?? [], newTemplate.files ?? []),
    ccs: normalizeCCs(oldTemplate.ccs, newTemplate.ccs),
    // These aren't used in P&S, so skipping them for now.
    editorFields: newTemplate.editorFields,
    recipients: normalizeRecipients(
      oldTemplate.recipients,
      newTemplate.recipients,
      true,
    ),
  };
  return [newTemplate, updatedTemplate];
}

function normalizeTemplates(
  oldTemplates: TemplateResponse[],
  newTemplates: TemplateResponse[],
): TemplateResponse[] {
  if (oldTemplates.length === newTemplates.length) {
    const updatedTemplates: UpdatedTemplateResponse[] = [];
    oldTemplates.forEach((oldTemplate: TemplateResponse, index: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_newTemplate, updatedTemplate] = normalizeTemplate([
        newTemplates[index],
        oldTemplate,
      ]);
      if (!('error' in updatedTemplate)) {
        updatedTemplates.push(updatedTemplate);
      }
    });
    return updatedTemplates;
  }
  return oldTemplates;
}

function normalizeFlags(oldFlags: Flags, newFlags: Flags): Flags {
  return {
    ...oldFlags,
    hasTemplateReuseWarning:
      oldFlags.hasTemplateReuseWarning ?? newFlags.hasTemplateReuseWarning,
    isResend: oldFlags.isResend ?? newFlags.isResend,
  };
}

function normalizeIntegrations(
  oldUploadIntegrations: UploadIntegrations,
  newUploadIntegrations: UploadIntegrations,
): UploadIntegrations {
  const updatedIntegrations = {
    ...oldUploadIntegrations,
  };
  if (newUploadIntegrations) {
    if (updatedIntegrations.D && newUploadIntegrations.D) {
      updatedIntegrations.D.nonce = newUploadIntegrations.D.nonce;
    }
    if (updatedIntegrations.T && newUploadIntegrations.T) {
      updatedIntegrations.T.nonce = newUploadIntegrations.T.nonce;
    }
  }
  return updatedIntegrations;
}

/**
 * The purpose of this normalization here is to ensure that both endpoints
 * actually contain the same data. Or to reconcile any differences, like
 * features we built into the new endpoint and are not backporting to the old
 * endpoint and data that's unique per request.
 */
function normalize(data: [DataSchema, DataSchema]): [DataSchema, DataSchema] {
  const [newEndpointData, oldEndpointData] = data;
  // Clone the root and upload integrations, so I can change the `nonce`
  const oldEndpoint = cloneDeep(oldEndpointData);

  // This is an intentional difference between the two APIs. The new endpoint
  // provides editorFeatures and we aren't backporting it to the old endpoint.
  oldEndpoint.editorFeatures = newEndpointData.editorFeatures;
  oldEndpoint.data.editorFields.initialPageRotation =
    newEndpointData.data.editorFields.initialPageRotation;

  if (
    oldEndpoint.flags.uploadIntegrations &&
    newEndpointData.flags.uploadIntegrations
  ) {
    oldEndpoint.flags.uploadIntegrations = normalizeIntegrations(
      oldEndpoint.flags.uploadIntegrations,
      newEndpointData.flags.uploadIntegrations,
    );
  }
  if (
    oldEndpoint.data.editorFields.fields.length === 0 &&
    newEndpointData.data.editorFields.fields.length !== 0
  ) {
    oldEndpoint.data.editorFields = {
      ...oldEndpoint.data.editorFields,
      fields: newEndpointData.data.editorFields.fields,
    };
  } else {
    oldEndpoint.data.editorFields = {
      ...oldEndpoint.data.editorFields,
      fields: normalizeFields(
        oldEndpoint.data.editorFields.fields,
        newEndpointData.data.editorFields.fields,
        false,
      ),
      pages: oldEndpoint.data.editorFields.pages.map((page) => {
        return { ...page };
      }),
    };
  }
  if (
    oldEndpoint.data.editorFields.pages.length === 0 &&
    newEndpointData.data.editorFields.pages.length !== 0
  ) {
    oldEndpoint.data.editorFields = {
      ...oldEndpoint.data.editorFields,
      pages: newEndpointData.data.editorFields.pages,
    };
  }
  if (
    oldEndpoint.data.editorFields.pages.length ===
    newEndpointData.data.editorFields.pages.length
  ) {
    oldEndpoint.data.editorFields.pages.forEach((page, index) => {
      if (
        page.src.match(/&timestamp=/) &&
        index < newEndpointData.data.editorFields.pages.length &&
        newEndpointData.data.editorFields.pages[index].src.match(/&timestamp=/)
      ) {
        const oldPageSrc = page.src.split('&timestamp=')[0];
        const newPageSrc =
          newEndpointData.data.editorFields.pages[index].src.split(
            '&timestamp=',
          )[0];
        if (oldPageSrc === newPageSrc) {
          page.src = newEndpointData.data.editorFields.pages[index].src;
        }
      }
    });
  }
  if (
    oldEndpoint.data.editorFields.rules.length === 0 &&
    newEndpointData.data.editorFields.rules.length !== 0
  ) {
    oldEndpoint.data.editorFields = {
      ...oldEndpoint.data.editorFields,
      rules: newEndpointData.data.editorFields.rules,
    };
  }
  if (
    oldEndpoint.data.ccs.length !== 0 &&
    newEndpointData.data.ccs.length !== 0 &&
    oldEndpoint.data.ccs.length === newEndpointData.data.ccs.length
  ) {
    oldEndpoint.data.ccs = normalizeCCs(
      oldEndpoint.data.ccs,
      newEndpointData.data.ccs,
    );
  }
  oldEndpoint.data.files = normalizeFiles(
    oldEndpoint.data.files,
    newEndpointData.data.files,
  );
  oldEndpoint.data.templates = normalizeTemplates(
    oldEndpoint.data.templates,
    newEndpointData.data.templates,
  );
  oldEndpoint.data.recipients = normalizeRecipients(
    oldEndpoint.data.recipients,
    newEndpointData.data.recipients,
  );
  oldEndpoint.flags = normalizeFlags(oldEndpoint.flags, newEndpointData.flags);
  return [newEndpointData, oldEndpoint];
}

export async function getUnifiedPrepAndSend(
  transmissionGroupGuid: string,
): Promise<DataSchema> {
  return loadDataWithFallback(
    'getPrepAndSendData',
    _getUnifiedPrepAndSend,
    getPrepAndSendData,
    [transmissionGroupGuid],
    normalize,
  );
}

export const saveUnifiedPrepAndSend = async (
  data: SaveDataType,
  user: User,
  uploadIntegrations: DataSchema['flags']['uploadIntegrations'],
  editorFeatures: DataSchema['editorFeatures'],
): Promise<void> => {
  const thisUser = {
    ...user,
    id: user.id,
    name: user.name ?? '',
    email: user.email ?? '',
    isPaidAccount: user.isPaidAccount ?? false,
    apiIdsEnabled: user.apiIdsEnabled ?? false,
    dbxUserId: user.dbxUserId,
  };
  const unifiedData = convertForHelloRequest(
    data,
    thisUser,
    uploadIntegrations,
    editorFeatures,
  );

  assertDataIsValid(validatePrepAndSendRequest, unifiedData);
  const response = await hsFetch(`/prep-and-send/unifiedSave/${data.guid}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(unifiedData),
  });

  if (response.status !== 200) {
    throw new Error('An error occured while saving P&S using Unified Data');
  }
};

export const savePrepAndSendData = async (
  data: SaveDataType,
): Promise<void> => {
  const response = await hsFetch(`/prep-and-send/save/${data.guid}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status !== 200) {
    throw new Error('An error occured while saving P&S');
  }
};

export const sendPrepAndSendRequest = async (
  data: SaveDataType,
  isFromChargeModal: boolean = false,
): Promise<SendRequestResponse> => {
  const agreedToChargeParam = isFromChargeModal
    ? `?agreed_to_charge=${isFromChargeModal}`
    : '';
  const response = await hsFetch(
    `/prep-and-send/send/${data.guid}${agreedToChargeParam}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );
  let responseParsed;
  try {
    const responseData = await response.json();
    if ('needs_charge' in responseData) {
      responseParsed = await sendRequestUiResponseSchema.validate(responseData);
    } else if ('template_status_id' in responseData) {
      responseParsed =
        await sendEmbeddedTemplateResponseSchema.validate(responseData);
    } else if ('signature_request_id' in responseData) {
      responseParsed =
        await sendEmbeddedRequestResponseSchema.validate(responseData);
    } else {
      responseParsed = await sendRequestUiResponseSchema.validate(responseData);
      if (
        response.status === 200 &&
        (responseParsed.redirectUrl || responseParsed.templateGuid)
      ) {
        responseParsed.success = Boolean(responseParsed.success);
      } else {
        responseParsed.success = false;
      }
    }
  } catch (e) {
    if (e instanceof SyntaxError) {
      throw new Error('Unable to parse response from P&S send');
    } else {
      throw e;
    }
  }

  if (
    response.status !== 200 &&
    'errors' in responseParsed &&
    (!responseParsed.errors || responseParsed.errors.length === 0)
  ) {
    throw new Error('Unhandled response from P&S send');
  }
  return responseParsed;
};

export const selfSavePrepAndSendRequest = async (
  data: SaveDataType,
): Promise<SelfSaveRequestResponse> => {
  const response = await hsFetch(`/prep-and-send/self-save/${data.guid}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  let responseParsed;
  try {
    responseParsed = await selfSaveRequestResponseSchema.validate(
      await response.json(),
    );
  } catch (e) {
    throw new Error('Unable to parse response from P&S self-save');
  }
  if (response.status === 200 && responseParsed.redirectUrl) {
    responseParsed.success = true;
  } else {
    responseParsed.success = false;
    if (
      response.status !== 200 &&
      (!responseParsed.errors || responseParsed.errors.length === 0)
    ) {
      throw new Error('Unhandled response from P&S self-save');
    }
  }
  return responseParsed;
};

type Prepare = {
  hasSignerOrder: boolean;
  files: UserFile[];
  templateGuid?: string;
  requestType: RequestTypes;
  preloadedTsmGroupKey: string;
  templateOneOffFile: boolean;
  recipients: Recipient[];
  hiddenStepper: boolean;
  parentUrl?: string;
  editedTemplateGuid?: null | string;
  locale?: null | string;
};
export const prepareEditor = async (prepare: Prepare): Promise<string> => {
  const formData = new FormData();
  formData.append('csrf_token', getCSRFToken());

  prepare.files
    .map((f) => f.guid)
    .filter(notEmpty)
    .forEach((guid) => {
      formData.append('snapshot_guids[]', guid);
    });
  formData.append('form_type', String(prepare.requestType));
  formData.append('is_reusable_link', '0');
  formData.append('preloaded_tsm_group_key', prepare.preloadedTsmGroupKey);
  formData.append('template_one_off_file', String(prepare.templateOneOffFile));
  formData.append('hidden_stepper', String(prepare.hiddenStepper));

  if (prepare.templateGuid) {
    formData.append('template_guid', prepare.templateGuid);
  }
  if (prepare.parentUrl) {
    formData.append('parent_url', prepare.parentUrl);
  }
  if (prepare.editedTemplateGuid) {
    formData.append('edited_template_guid', prepare.editedTemplateGuid);
  }
  if (prepare.locale) {
    formData.append('user_culture', prepare.locale);
  }

  const recipients = [...prepare.recipients];

  for (let i = 0; i < recipients.length; i++) {
    const recipient = recipients[i];

    switch (recipient.type) {
      case RecipientTypes.Signer:
        formData.append(`recipients[${recipient.id}]`, recipient.email);
        formData.append(`recipient_names[${recipient.id}]`, recipient.name);
        if (prepare.hasSignerOrder) {
          formData.append(`signer_order[${recipient.id}]`, String(i));
        }
        break;

      case RecipientTypes.Role:
        formData.append(`recipient_names[${recipient.id}]`, recipient.name);
        if (prepare.hasSignerOrder) {
          formData.append(`signer_order[${recipient.id}]`, String(i));
        }
        break;

      case RecipientTypes.Fax:
        break;
      default:
        unreachable(recipient);
    }
  }

  const response = await hsFetch('/editor/prepare', {
    method: 'POST',
    body: formData,
  });

  const data = await response.json();

  if (typeof data.url === 'string') {
    return data.url;
  }
  throw new Error('Unhandled response from editor/prepare');
};
export const downloadFile = async (url: string) => {
  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  if (response.status === 500) {
    // Super group XX is not downloadable by account YY and this is due some BE delay with self-save
    // a retry actually does allow a download. Imitating the same bahaviour when we
    // redirect to document/manage for UI behavior.
    // https://github.com/HelloFax/HelloFax/blob/trunk/apps/webapp/modules/home/templates/_manage_js.php#L19-L45
    // let's reconnect until the file is ready to download.
    await downloadFile(url);
  } else if (response.status === 200) {
    const { url } = response;
    window.open(url, '_self');
    await new Promise((resolve) => setTimeout(resolve, 3000));
  }
};

export const getTemplate = async (
  preloadedTsmGroupKey: string,
  templateId: string,
  isBulkSend: boolean,
): Promise<TemplateResponse | TemplateErrorResponse> => {
  const response = await hsFetch(
    `/prep-and-send/use-template/${preloadedTsmGroupKey}${queryParams({
      template_id: templateId,
      is_bulk_send: isBulkSend ? 1 : 0,
    })}`,
  );
  return response.json();
};

const _getUnifiedTemplate = async (
  preloadedTsmGroupKey: string,
  templateId: string,
  isBulkSend: boolean,
): Promise<TemplateResponse | TemplateErrorResponse> => {
  const response = await hsFetch(
    `/prep-and-send/use-unified-template/${preloadedTsmGroupKey}${queryParams({
      template_id: templateId,
      is_bulk_send: isBulkSend ? 1 : 0,
    })}`,
  );
  const unifiedTemplate = await response.json();
  assertDataIsValid(validateTemplate, unifiedTemplate);

  const data = convertTemplates(unifiedTemplate, preloadedTsmGroupKey);
  return data;
};

export async function getUnifiedTemplate(
  preloadedTsmGroupKey: string,
  templateId: string,
  isBulkSend: boolean,
): Promise<TemplateResponse | TemplateErrorResponse> {
  return loadDataWithFallback(
    'getTemplate',
    _getUnifiedTemplate,
    getTemplate,
    [preloadedTsmGroupKey, templateId, isBulkSend],
    normalizeTemplate,
  );
}

const phpStatusUploadFile = Yup.object({
  name: Yup.string().required(),
  type: Yup.string().required(),
  root_snapshot_guid: Yup.string().required(),
  pw_required: Yup.bool().default(false),
});

// Upload file from user's device
export const uploadFile = async (
  transmissionGroupGuid: string,
  file: File,
  replaceSnapshotGuid?: string,
): Promise<FileUploadResponse | 'TooManyPages' | 'unknown'> => {
  const url = `/attachment/upload/form_id/tsm_group_request/preloaded_tsm_group_key/${transmissionGroupGuid}/load_pdf_info/1`;

  const formData = new FormData();
  formData.append('file', file);

  if (replaceSnapshotGuid) {
    formData.append('replace_snapshot_guid', replaceSnapshotGuid);
  }

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200) {
    try {
      const rawData = await response.json();
      if (rawData.errors) {
        const numSnapshotError =
          rawData.errors['tsm_group_request[num_snapshots]'];
        if (numSnapshotError && numSnapshotError.match(/maximum page count/g)) {
          return 'TooManyPages';
        }
        return 'unknown';
      } else {
        const data = await phpStatusUploadFile.validate(rawData);

        return {
          name: data.name,
          rootSnapshotGuid: data.root_snapshot_guid,
          pwRequired: data.pw_required,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  Sentry.captureException(
    new Error(`Error uploading file. HTTP ${response.status}`),
  );

  return 'unknown';
};

// Delete a user-uploaded file
export const deleteFile = async (
  transmissionGroupGuid: string,
  file: UserFile,
): Promise<void> => {
  // if there's an error & a file gets automatically deleted, pass the root snapshot guid
  // since that's the only available snapshot in the database, otherwise pass in the
  // file's guid (user triggering the delete)
  const guid = file.guid || file.rootSnapshotGuid;
  const url = `/attachment/delete${queryParams({
    snapshot_guid: guid,
    preloaded_tsm_group_key: transmissionGroupGuid,
    csrf_token: getCSRFToken(),
    c: Math.random(),
  })}`;

  const response = await hsFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });

  // If the user was attempting to delete a file and it's 404 not found, then
  // it's fine because there's nothing there to delete.
  if (response.status === 200 || response.status === 404) {
    return;
  }

  throw new Error('Unhandled response from attachment delete');
};

/**
 * Delete template files for now, naming it "deleteFiles" so we can probably
 * use the endpoint for uploaded file deletion in the future when we
 * switch to using the prep-and-send delete endpoint
 */
export const deleteFiles = async (
  fileGuids: string[],
  preloadedTsmGroupKey: string,
  templateGuid?: string,
  uniqueTokenMap?: { [key: string]: string },
): Promise<FilesDeleteResponse> => {
  const url = `/prep-and-send/delete-files/${preloadedTsmGroupKey}${queryParams(
    {
      c: Math.random(),
    },
  )}`;

  const request = {
    files: fileGuids,
    unique_token_map: uniqueTokenMap || null,
    csrf_token: getCSRFToken(),
    template_guid: templateGuid || null,
  };

  const response = await hsFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  const data = await response.json();
  if (response.status === 200) {
    return {
      success: data.success,
    };
  } else if (response.status === 400) {
    return {
      success: data.success,
      error: data.error,
    };
  }

  throw new Error('Unhandled response from P&S delete-files');
};

export const setFilePassword = async (
  rootSnapshotGuid: UserFile['rootSnapshotGuid'],
  password: string,
): Promise<FileSetPasswordResponse> => {
  const url = `/attachment/setPassword${queryParams({
    c: Math.random(),
  })}`;

  const formData = new FormData();
  formData.append('password', password);
  formData.append('csrf_token', getCSRFToken());
  formData.append('root_snapshot_guid', rootSnapshotGuid);

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('Unhandled response from attachment/setPassword');
};

// Yup validation for BE call to conversionStatus
const phpStatusPollFile = {
  [FilePollResponseStatus.Converting]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Converting>(),
    progress: Yup.number(),
    total: Yup.number(),
  }),
  [FilePollResponseStatus.Ok]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Ok>(),
    page_count: Yup.number(),
    guid: Yup.string(),
    tsm_group_guid: Yup.string(),
    has_overlay_data: Yup.bool(),
    is_landscape: Yup.bool(),
    replace_data: Yup.object({
      replaceSnapshotGuid: Yup.string(),
      replaceParentSnapshotId: Yup.string(),
      replaceDocumentId: Yup.string(),
      replaceDocumentName: Yup.string(),
      documentId: Yup.string(),
      documentName: Yup.string(),
    }).nullable(),
    document_guid: Yup.string(),
  }),
  [FilePollResponseStatus.Deleted]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Deleted>(),
  }),
  [FilePollResponseStatus.Error]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Error>(),
    description: Yup.string(),
  }),
  [FilePollResponseStatus.Error404]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Error404>(),
    description: Yup.string(),
  }),
  [FilePollResponseStatus.PasswordRequired]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.PasswordRequired>(),
  }),
  [FilePollResponseStatus.TooManyPages]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.TooManyPages>(),
    max_pages: Yup.number(),
  }),
  // these statuses are applicable for external file downloads (integrations)
  [FilePollResponseStatus.Downloading]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Downloading>(),
  }),
  [FilePollResponseStatus.FileDownloaded]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.FileDownloaded>(),
    is_import: Yup.bool(),
    root_snapshot_guid: Yup.string(),
    root_snapshot_is_accessible: Yup.bool(),
    service_type: Yup.string(),
    name: Yup.string(),
  }),
  [FilePollResponseStatus.FileTooLarge]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.FileTooLarge>(),
  }),
  [FilePollResponseStatus.RetrieveError]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.RetrieveError>(),
  }),
  [FilePollResponseStatus.BadAuth]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.BadAuth>(),
    auth_url: Yup.string(),
  }),
  [FilePollResponseStatus.BadRequest]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.BadRequest>(),
  }),
};

// I'd really like to just build `phpStatusPollFile` inline, but I couldn't figure
// out how to get TypeScript to inver `PollResponse` from `phpStatusPollFile`.
const pollSchema = lazyUnion<InferUnion<typeof phpStatusPollFile>, 'status'>(
  'status',
  phpStatusPollFile,
);
// Poll API for status of file conversion operation
export const pollFile = async (
  transmissionGroupGuid: string,
  file: UserFile,
  requestType: RequestTypes,
  uniqueToken: string,
  clientId?: string,
  isResend?: boolean,
): Promise<FilePollResponse> => {
  const isTemplate = file.type === UserFileTypes.Template ? 1 : 0;
  const url = `/attachment/conversionStatus/is_template/${isTemplate}/form_type_code/${requestType}${queryParams(
    {
      is_from_resend: isResend,
      preloaded_tsm_group_key: transmissionGroupGuid,
      root_snapshot_guid: file.rootSnapshotGuid,
      unique_token: uniqueToken,
      c: Math.random(),
      draft_snapshot_guid: file.draftSnapshotGuid || undefined,
      client_id: clientId || undefined,
    },
  )}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await pollSchema.validate(await response.json());

    switch (data.status) {
      case FilePollResponseStatus.Converting:
        return {
          status: data.status,
          progress: data.progress,
          total: data.total,
        };
      case FilePollResponseStatus.Ok:
        return {
          status: data.status,
          pageCount: data.page_count,
          guid: data.guid,
          tsmGroupGuid: data.tsm_group_guid,
          hasOverlayData: data.has_overlay_data,
          isLandscape: data.is_landscape,
          replaceData: data.replace_data,
          documentGuid: data.document_guid,
        };
      case FilePollResponseStatus.Deleted:
        return {
          status: data.status,
        };
      case FilePollResponseStatus.PasswordRequired:
        return {
          status: data.status,
        };
      case FilePollResponseStatus.TooManyPages:
        return {
          status: data.status,
          maxPages: data.max_pages,
        };
      case FilePollResponseStatus.FileTooLarge:
        return {
          status: data.status,
        };
      case FilePollResponseStatus.Error:
      case FilePollResponseStatus.Error404:
        return {
          status: data.status,
          description: data.description,
        };
      case FilePollResponseStatus.Downloading:
      case FilePollResponseStatus.FileDownloaded:
      case FilePollResponseStatus.RetrieveError:
      case FilePollResponseStatus.BadAuth:
      case FilePollResponseStatus.BadRequest:
        // these statuses aren't applicable to this endpoint and shouldn't
        // be receiving these for any reason, so breaking to throw error
        break;
      default:
        unreachable(data);
    }
  } else if (response.status === 404 && isTemplate) {
    // We might get here because a BE mutex lock timeout
    return {
      status: FilePollResponseStatus.Error404,
    };
  }
  throw new Error('Unhandled response polling for conversion status');
};

const phpStatusPollEmbedded = {
  [FilePollResponseStatus.Ok]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Ok>(),
  }),
  [FilePollResponseStatus.Converting]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Converting>(),
  }),
  [FilePollResponseStatus.Error]: Yup.object({
    status: Yup.mixed<FilePollResponseStatus.Error>(),
    app_info: Yup.object({
      app_name: Yup.string(),
      app_owner_email: Yup.string(),
    }).notRequired(),
  }),
};

export type EmailValidationResponse = {
  isDeliverable: boolean;
  error?: string;
};

export type CheckExternalSharingResponse = {
  isExternalSharingDisabled: boolean;
};

export const validateEmail = async (
  email: string,
): Promise<EmailValidationResponse> => {
  const formData = new FormData();
  formData.append('email_address', email);

  const response = await hsFetch('/endpoint/validateEmail', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  const { data } = await response.json();

  return {
    isDeliverable: data.is_deliverable,
    error: data?.error_message,
  };
};

export const checkExternalSharing = async (
  recipientEmailAddress: string,
): Promise<CheckExternalSharingResponse> => {
  const formData = new FormData();
  formData.append('recipient_email_address', recipientEmailAddress);

  const response = await hsFetch('/endpoint/checkExternalSharing', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  const { data } = await response.json();

  return {
    isExternalSharingDisabled: data?.is_external_sharing_disabled,
  };
};

const pollEmbeddedSchema = lazyUnion<
  InferUnion<typeof phpStatusPollEmbedded>,
  'status'
>('status', phpStatusPollEmbedded);

export const pollEmbeddedFiles = async (
  docGuids: string[],
): Promise<EmbeddedPollResponse> => {
  // backend needs it as an array
  const guids = docGuids.map((guid) => `doc_guids[]=${guid}`).join('&');
  const url = `/attachment/conversionStatus?${guids}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await pollEmbeddedSchema.validate(await response.json());

    switch (data.status) {
      case FilePollResponseStatus.Ok:
      case FilePollResponseStatus.Converting: {
        return {
          status: data.status,
        };
      }
      case FilePollResponseStatus.Error: {
        let appInfo;
        if (data.app_info) {
          appInfo = {
            appName: data.app_info.app_name,
            appOwnerEmail: data.app_info.app_owner_email,
          };
        }
        return {
          status: data.status,
          appInfo,
        };
      }
      default:
        unreachable(data);
    }
  }

  throw new Error('Unhandled response polling for embedded conversion status');
};

// Queue download file from external service
export const externalFileDownload = async (
  serviceType: string,
  fileReference: string,
  fileName: string,
  token: string,
  preloadedTsmGroupKey?: string,
  replaceSnapshotGuid?: string,
): Promise<ExternalFileDownloadResponse> => {
  const url = `/attachment/externalFile${queryParams({
    service_type: serviceType,
    file_reference: fileReference,
    file_name: fileName,
    c: Math.random(),
    preloaded_tsm_group_key: preloadedTsmGroupKey,
    replace_snapshot_guid: replaceSnapshotGuid,
    token,
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await response.json();

    return {
      status: data.status,
      cacheKey: data.cache_key,
    };
  }

  throw new Error('Unhandled response from attachment/externalFile');
};

// Poll API for status of external file download operation
export const externalFileProgress = async (
  transmissionGroupGuid: string,
  cacheKey: string,
): Promise<FilePollResponse> => {
  const url = `/attachment/externalFileDownloadProgress${queryParams({
    preloaded_tsm_group_key: transmissionGroupGuid,
    cache_key: cacheKey,
    c: Math.random(),
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await pollSchema.validate(await response.json());

    switch (data.status) {
      case FilePollResponseStatus.FileDownloaded:
        return {
          status: data.status,
          isImport: data.is_import,
          serviceType: data.service_type,
          name: data.name,
          rootSnapshotGuid: data.root_snapshot_guid,
          rootSnapshotIsAccessible: data.root_snapshot_is_accessible,
        };
      case FilePollResponseStatus.Downloading:
      case FilePollResponseStatus.FileTooLarge:
      case FilePollResponseStatus.RetrieveError:
      case FilePollResponseStatus.BadRequest:
        return {
          status: data.status,
        };
      case FilePollResponseStatus.BadAuth:
        return {
          status: data.status,
          authUrl: data.auth_url,
        };
      case FilePollResponseStatus.Converting:
      case FilePollResponseStatus.Ok:
      case FilePollResponseStatus.Deleted:
      case FilePollResponseStatus.PasswordRequired:
      case FilePollResponseStatus.TooManyPages:
        // these statuses aren't applicable to this endpoint and shouldn't
        // be receiving these for any reason, so breaking to throw error
        break;
      case FilePollResponseStatus.Error:
        return {
          status: FilePollResponseStatus.Error,
        };
      case FilePollResponseStatus.Error404:
        return {
          status: FilePollResponseStatus.Error404,
        };
      default:
        unreachable(data);
    }
  }

  throw new Error('Unhandled response checking external file progress');
};

export const externalFolderContents = async (
  serviceType: string,
  offset: number,
  folderReference: string,
): Promise<ExternalFolderResponse> => {
  const url = `/attachment/externalFolderContents${queryParams({
    service_type: serviceType,
    offset,
    folder_reference: folderReference,
    c: Math.random(),
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await response.json();

    if (data.auth_url) {
      // these are returned for unauthorized integrations
      return {
        authUrl: data.auth_url,
        boxReAuth: data.box_reauth || false,
        googleReAuth: data.google_reauth || false,
      };
    }

    return {
      files: data.files,
      folders: data.folders,
      pageSize: data.page_size,
    };
  }

  throw new Error('Unhandled response getting external folder contents');
};

export const externalServiceAuthStatus = async (
  serviceType: string,
): Promise<ExternalServiceAuthStatusResponse> => {
  const url = `/account/externalServiceAuthStatus${queryParams({
    service_type: serviceType,
    c: Math.random(),
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await response.json();

    return {
      authorized: data.authorized,
      failed: data.failed,
      errorMessage: data.error_msg,
    };
  }

  throw new Error('Unhandled response from externalServiceAuthStatus');
};

// Reorder uploaded files
export const reorderFiles = async (
  transmissionGroupGuid: string,
  files: UserFilesKeyed,
): Promise<FileReorderResponse> => {
  const url = `/attachment/asyncReorderSnapshots${queryParams({
    c: Math.random(),
  })}`;

  const formData = new FormData();
  formData.append('preloaded_tsm_group_key', transmissionGroupGuid);
  formData.append('csrf_token', getCSRFToken());
  Object.values(files)
    .filter(notEmpty)
    .forEach((file) => {
      formData.append(`snapshot_order[${file.guid}]`, String(file.order));
    });

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200) {
    const data = await response.json();

    return data;
  }

  throw new Error('Unhandled response reordering files');
};
// update account, BE determines the site and
// updates received for FAX and requested for SR
export const updateAccount = async (
  firstName: string,
  lastName: string,
  shouldIncludePdfs: boolean,
  shouldIncludePdfsForOthers: boolean,
  isHelloFax: boolean,
): Promise<UpdateAccountResponse> => {
  const url = `/account/update${queryParams({
    c: Math.random(),
    ajax: true,
  })}`;

  const formData = new FormData();
  formData.append('edit_account[first_name]', firstName);
  formData.append('edit_account[last_name]', lastName);
  if (isHelloFax) {
    formData.append(
      'edit_account[should_include_received_fax_pdfs]',
      String(shouldIncludePdfs),
    );
    formData.append(
      'edit_account[should_include_received_fax_pdfs_for_others]',
      String(shouldIncludePdfsForOthers),
    );
    // should we change this to snake case to match othes? if so BE needs the change tooo.
    formData.append('mode', 'update-fax-pdf-attachments');
  } else {
    formData.append(
      'edit_account[should_include_requested_pdfs]',
      String(shouldIncludePdfs),
    );
    formData.append(
      'edit_account[should_include_requested_pdfs_for_others]',
      String(shouldIncludePdfsForOthers),
    );
    formData.append('mode', 'update_name');
  }
  formData.append('edit_account[_csrf_token]', getCSRFToken());

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  return response.json();
};

const templateCreateStatusResponse = {
  error: Yup.object().shape({
    status: Yup.mixed<'error'>(),
    description: Yup.string().notRequired().default(undefined),
  }),
  pending: Yup.object().shape({
    status: Yup.mixed<'pending'>(),
  }),
  ok: Yup.object().shape({
    status: Yup.mixed<'ok'>(),
    template_guid: Yup.string().required(),
    is_reusable_link: Yup.boolean().required(),
    reusable_link_was_edited: Yup.boolean().required(),
    manage_page_url: Yup.string(),
  }),
};
const templateCreateStatusResponseSchema = lazyUnion<
  InferUnion<typeof templateCreateStatusResponse>,
  'status'
>('status', templateCreateStatusResponse);

export const getCreateTemplateStatus = async (
  guid: string,
  embedded: boolean = false,
): Promise<TemplateCreateStatus> => {
  let url = `/createTemplateStatus${queryParams({
    guid,
    c: Math.random(),
  })}`;

  if (embedded) {
    url = `/prep-and-send${url}`;
  } else {
    url = `/send${url}`;
  }

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await templateCreateStatusResponseSchema.validate(
      await response.json(),
    );

    switch (data.status) {
      case 'pending':
        return {
          status: data.status,
        };
      case 'error':
        return {
          status: data.status,
          description: data.description,
        };
      case 'ok':
        return {
          status: data.status,
          templateGuid: data.template_guid,
          isReusableLink: data.is_reusable_link,
          reusableLinkWasEdited: data.reusable_link_was_edited,
          managePageUrl: data.manage_page_url,
        };
      default:
        unreachable(data);
    }
  }

  throw new Error('Unhandled response from createTemplateStatus');
};

const contactSchema = Yup.object({
  id: Yup.number(),
  value: Yup.string(),
  guid: Yup.string(),
  numUses: Yup.number(),
  // Email or Fax
  typeCode: Yup.mixed().oneOf(['E', 'F']),
  contact: Yup.object({
    id: Yup.number(),
    // Automatically convert `null` names to an empty string
    name: Yup.string().default(''),
  }),
}).camelCase();

export const getReferAFriendURL = async (): Promise<string> => {
  const response = await hsFetch('/account/getReferAFriendURL');
  const data = await response.json();

  return data.url;
};
export const getContactImportStatus = async (
  type: 'gmail' | 'yahoo',
): Promise<ImportContactsStatusResponse> => {
  const url = appendQueryParameters('/account/getContactImportStatus', {
    type,
    ignore_timeout: 1,
    c: Math.random(),
  });

  const response = await hsFetch(url);
  const data = await response.json();

  return {
    success: data.success,
    complete: data.complete,
    progress: data.progress,
    count: data.count,
  };
};

type InviteResponse = {
  success: boolean;
  invitesSent: number;
};
export const inviteFriends = async (
  emails: string[],
): Promise<InviteResponse> => {
  const formData = new FormData();
  formData.append('sendinviteemails[_csrf_token]', getCSRFToken());
  emails.forEach((email) => formData.append('emails[]', email));

  const response = await hsFetch('/account/asyncSendInviteEmails', {
    method: 'POST',
    body: formData,
  });
  const data = await response.json();

  return {
    success: data.success,
    invitesSent: data.invites_sent,
  };
};
export const getAllContacts = async (): Promise<Contact[]> => {
  const response = await hsFetch(`/account/getAllContacts?c=${Math.random()}`, {
    headers: {
      // If you don't include this you either get a 404 or a 500
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    try {
      const data: Record<Contact['value'], Contact> = await response.json();
      const serverContacts = await Yup.array()
        .of(contactSchema)
        .validate(Object.values(data));

      return serverContacts.map((c): Contact => {
        return {
          id: c.guid,
          name: c.contact.name,
          value: c.value,
          type: c.typeCode,
          numUses: c.numUses,
        };
      });
    } catch (err) {
      throw new Error(`Error converting contacts response: ${err}`);
    }
  }

  throw new Error(`Error fetching contacts: ${response.statusText}`);
};

const bulkSendExampleResponseSchema = Yup.object<ExampleFields>({
  fields: Yup.array().of(Yup.string()).min(1).required(),
});

const bulkSendDataSchemaNewResponseSchema = Yup.object({
  success: Yup.boolean(),
  csv_headers: Yup.array().of(Yup.string()).ensure(),
  sample_row: Yup.array().of(Yup.string()).ensure(),
});

const bulkSendDataUploadSignersResponseSchema = Yup.object({
  success: Yup.boolean(),
  header: Yup.array().of(Yup.string()).ensure(),
  signers: Yup.array().of(Yup.object()).ensure(),
  rowErrors: Yup.array().of(Yup.object({})).ensure(),
  errors: Yup.array().of(Yup.string()).ensure(),
});

const bulkSendDataDeleteSignersResponseSchema = Yup.object({
  success: Yup.boolean(),
});

const bulkSendValidateErrorResponseSchema = Yup.object().shape({
  error: Yup.string().required(),
});

type BulkSendValidateErrorResponse = Yup.InferType<
  typeof bulkSendValidateErrorResponseSchema
>;

const bulkSendInitResponseSchema = Yup.object<BulkSendInitResponse>({
  signerKey: Yup.string().required(),
  isCcEnabled: Yup.boolean(),
  form: Yup.object({
    lockedTitle: Yup.string().nullable(),
    lockedMessage: Yup.string().nullable(),
  }).camelCase(),
}).camelCase();

export const initBulkSend = async (
  cacheKey: string,
): Promise<BulkSendInitResponse> => {
  const url = `/bulksend/init?cache_key=${cacheKey}`;

  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  if (response.status === 200) {
    const data = await response.json();
    return bulkSendInitResponseSchema.validate(data);
  }

  throw new Error(response.statusText);
};

export const getBulkSendExample = async (
  templates: TemplateResponse[],
): Promise<ExampleFields> => {
  const url = `/bulksend/example${queryParams({
    'template_ids[]': templates.map((t) => t.templateGuid),
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    const data = await response.json();
    return bulkSendExampleResponseSchema.validate(data);
  }
  throw new Error(response.statusText);
};

export const validateBulkSendData = async (
  file: File,
  signerKey: string,
  templateIds: string[],
): Promise<null | BulkSendValidateErrorResponse> => {
  const url = `/bulksend/signers${queryParams({
    signer_key: signerKey,
    'template_ids[]': templateIds,
  })}`;

  const formData = new FormData();
  formData.append('signer_file', file);
  formData.append('csrf_token', getCSRFToken());

  const response = await hsFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });
  if (response.status !== 200) {
    return bulkSendValidateErrorResponseSchema.validate(await response.json());
  }
  return null;
};

const bulkSendSubmitResponseSchema = Yup.object().shape({
  bulk_send_job_id: Yup.string().required(),
  total: Yup.number().required(),
});

export const submitBulkSendRequest = async (
  signerKey: string,
  title: string,
  message: string,
  ccs: CC[],
): Promise<string> => {
  const url = '/bulksend';
  const formData = new FormData();
  formData.append('signer_key', signerKey);
  formData.append('title', title);
  formData.append('message', message);
  if (ccs && ccs.length) {
    ccs.forEach((cc, i) => {
      if (cc.type === 'ccEmail' && cc?.email && cc.role?.name) {
        formData.append(`ccs[${i}][email_address]`, cc.email);
        formData.append(`ccs[${i}][role][name]`, cc.role.name);
      }
    });
  }
  formData.append('csrf_token', getCSRFToken());
  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200) {
    await bulkSendSubmitResponseSchema.validate(await response.json());
    return '/home/manage?filter=bulk&status=bulk&sent=bulk';
  }

  throw new Error(response.statusText);
};

export const bulkSendInitialize = async (
  transmissionGroupGuid: string,
  templateGuids: string[],
): Promise<string> => {
  const response = await hsFetch('/bulksend/initialize', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      template_guids: templateGuids,
      preloaded_tsm_group_key: transmissionGroupGuid,
    }),
  });
  if (response.status === 200 && response.ok) {
    const result = await response.json();
    if (!result.success) {
      throw new Error(result.error);
    }
    return result.bulk_send_data_key;
  }
  throw new Error(response.statusText);
};

export const getBulkSendExampleNew = async (bulkSendDataKey: string) => {
  const url = `/bulksend/downloadCsvTemplate${queryParams({
    bulk_send_data_key: bulkSendDataKey,
  })}`;
  const response = await hsFetch(url, {
    method: 'GET',
  });

  if (response.status === 200 && response.ok) {
    const data = await response.json();
    return bulkSendDataSchemaNewResponseSchema.validate(data);
  }
  throw new Error(response.statusText);
};

export const uploadSignerFileBulkSend = async (
  bulkSendDataKey: string,
  signersFile: File,
) => {
  const formData = new FormData();
  formData.append('bulk_send_data_key', bulkSendDataKey);
  formData.append('bulk_send_signers_file', signersFile);
  formData.append('csrf_token', getCSRFToken());
  const response = await hsFetch('/bulksend/uploadSigners', {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200 && response.ok) {
    const data = await response.json();
    return bulkSendDataUploadSignersResponseSchema.validate(data);
  }

  if (response.status === 400) {
    const data = await response.json();
    throw new Error(data.error);
  }
  throw new Error(response.statusText);
};

export const deleteSignerFileBulkSend = async (bulkSendDataKey: string) => {
  const formData = new FormData();
  formData.append('bulk_send_data_key', bulkSendDataKey);
  formData.append('csrf_token', getCSRFToken());
  const response = await hsFetch('/bulksend/deleteSigners', {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200 && response.ok) {
    const data = await response.json();
    return bulkSendDataDeleteSignersResponseSchema.validate(data);
  }

  if (response.status === 400) {
    const data = await response.json();
    throw new Error(data.error);
  }
  throw new Error(response.statusText);
};
