/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  AutofillFieldTypes,
  Document,
  Field,
  getAutoFillFieldType,
  Page,
  Signer,
} from 'signer-app/types/editor-types';
import type { Rule } from 'signer-app/conditional-logic';
import {
  AutoFillType,
  CCEmail,
  CCRole,
  CheckboxField,
  Color,
  DateField,
  DropdownField,
  EditorData,
  FontFamily,
  FontSize,
  HyperlinkField,
  InitialsField,
  IntegrationNames,
  JSDateFormat,
  MergeField,
  NonNumericOperator,
  NumericOperator,
  PrepAndSendRequest,
  RadioField,
  RequestType,
  Role,
  Signature,
  SignatureField,
  SignatureRequestIntegrationMetadata,
  SignatureType,
  StringArrayOperator,
  StringOperator,
  TextField,
  ValidationType,
} from 'js/sign-components/generated/types/HelloRequest';
import { UnreachableError } from 'js/sign-components/common/ts-utils';
import { identity } from 'lodash';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import type { UploadIntegrations } from 'hellospa/page/prep-and-send/data/types/integration';
import type { IntegrationMetadata } from 'hellospa/components/editor/context/editor-context';
import type { EditorFeatureMap } from 'hellospa/components/editor/constants';

/**
 * This is a way to get the transport layer to use HelloRequest. Later as Editor
 * gets updated to work directly with some of these other types, we'll start
 * dropping these conversions. That's also why they each have their own function.
 *
 */
export function convertForEditor(
  unifiedData: PrepAndSendRequest,
): LegacyEditorData {
  const editorData = unifiedData.requestData.editorData;

  const signers = editorData.signers.map(convertRecipients);

  const data: LegacyEditorData = {
    pages: editorData.pages.map(convertUnifiedPages),
    fields: editorData.fields.map(convertUnifiedFields),
    mergeFields: editorData.mergeFields.map(convertMergeField),
    pdfFields: editorData.pdfFields.map(convertUnifiedFields),
    rules: editorData.rules.map(convertUnifiedRules),
    preparer: makePreparer(unifiedData.user),
    snapshots: convertSnapshots(editorData),
    documents: editorData.documents,
    signers,
    ccs: unifiedData.requestData.ccs.map(convertCCs),

    uploadIntegrations: convertIntegrations(
      unifiedData.accountSettings.uploadIntegrations,
    ),
    pnsRequestType: convertRequestType(unifiedData.flags.requestType),

    options: convertOptions(unifiedData, signers),
    signerOptions: convertSignerOptions(unifiedData),
    signerOrder: convertSignerOrder(editorData.signers),
    currentRoleId: convertCurrentRoleId(unifiedData),

    /**
     * Is this correct?
     */
    initialPageRotation:
      unifiedData.requestData.editorData.transform?.rotate ?? 0,
  };
  return data;
}

export const convertIntegrations = (
  data: PrepAndSendRequest['accountSettings']['uploadIntegrations'],
): LegacyEditorData['uploadIntegrations'] => {
  const returnValues: Partial<LegacyEditorData['uploadIntegrations']> = {};

  Object.keys(data).forEach((key) => {
    const tmp = data[key as keyof typeof data]!;

    switch (tmp.id) {
      case 'D':
        returnValues[tmp.id] = tmp;
        return tmp;
      case 'T':
        returnValues[tmp.id] = {
          ...tmp,
          token: tmp.token,
        };
        return tmp;
      case 'E':
        returnValues[tmp.id] = tmp;
        return tmp;
      case 'G':
        returnValues[tmp.id] = {
          ...tmp,
          token: tmp.token,
          loginHint: tmp.loginHint,
        };
        return tmp;
      case 'B':
        returnValues[tmp.id] = tmp;
        return tmp;
      default:
        throw new UnreachableError(tmp);
    }
  });

  return returnValues;
};

const convertSnapshots = (
  editorData: EditorData,
): LegacyEditorData['snapshots'] => {
  return editorData.pages.reduce(
    (snapshots, page, pageIndex) => {
      snapshots[page.snapshotGuid!] ??= [];
      snapshots[page.snapshotGuid!]?.push(pageIndex);
      return snapshots;
    },
    {} as LegacyEditorData['snapshots'],
  );
};

const convertRequestType = (
  type: PrepAndSendRequest['flags']['requestType'],
): LegacyEditorData['pnsRequestType'] => {
  return type;
};

const convertOptions = (
  unifiedData: PrepAndSendRequest,
  signers: Signer[],
): LegacyEditorData['options'] => {
  const editorData = unifiedData.requestData.editorData;

  const isEmbeddedRequest = Boolean(
    unifiedData.embeddedData?.isEmbeddedRequest,
  );

  const isMeOnly = Boolean(unifiedData.options.isMeOnly);
  const isDropbox =
    unifiedData.flags.integration?.name === IntegrationNames.DeepIntegration;
  const noSigners = signers.length === 0;

  return {
    isTemplate:
      unifiedData.flags.requestType === RequestType.Template ||
      unifiedData.flags.requestType === RequestType.EmbeddedTemplate,
    isTemplateLink: unifiedData.flags.requestType === RequestType.ReusableLink,
    shouldRequestSigners: Boolean(!isMeOnly && isDropbox && noSigners),

    accountDateFormat: unifiedData.user.accountDateFormat,
    apiIdsEnabled: unifiedData.user.apiIdsEnabled,
    dateFormat: unifiedData.options.dateFormat ?? 'default',
    embedded: {
      appName: unifiedData.embeddedData?.appName ?? null,
      editedTemplateGuid: unifiedData.embeddedData?.editedTemplateGuid ?? null,
      embeddedScript:
        'https://cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js',
      forceEditSubjectMessage: Boolean(unifiedData.options.forceSubjectMessage),
      hideContinueButton: Boolean(unifiedData.options.hideContinueButton),
      isDropbox,
      isEmbeddedRequest,
      isEmbeddedTemplate: Boolean(unifiedData.embeddedData?.isEmbeddedTemplate),
      isCreatedFromTemplate: Boolean(
        unifiedData.embeddedData?.isCreatedFromTemplate,
      ),
      isGmail: Boolean(unifiedData.embeddedData?.isGmail),
      isNewPrepAndSend: true,
      parentUrl: unifiedData.embeddedData?.parentUrl ?? null,
      redirectUrl: unifiedData.embeddedData?.redirectUrl ?? null,
      skipMeNow: Boolean(unifiedData.embeddedData?.skipMeNow),
      // TODO: If not comparing with results of getData,
      // set this similar to how message is being set
      subject:
        unifiedData.requestData.title.length > 0
          ? unifiedData.requestData.title
          : null,
      message: isEmbeddedRequest ? unifiedData.requestData.message : null,
      integrationMetadata: unifiedData.options.integrationMetadata
        ? convertIntegrationMetadata(unifiedData.options.integrationMetadata)
        : null,
    },
    features: convertEditorFeatures(unifiedData.options.features),
    isMeOnly,
    permissions: {
      canUseCustomRegexDataValidation:
        editorData.editorOptions.canUseCustomRegexDataValidation,
      canUseDataValidation: editorData.editorOptions.canUseDataValidation,
    },
  };
};

const convertIntegrationMetadata = (
  integration: SignatureRequestIntegrationMetadata,
): LegacyEditorData['options']['embedded']['integrationMetadata'] => {
  return {
    associatedObjectType: integration.associatedObjectType,
    context: integration.context ?? '',
    showMissingFieldsTooltip: integration.showMissingFieldsTooltip ?? false,
  };
};

const convertEditorFeatures = (
  features: Array<string> = [],
): Array<keyof EditorFeatureMap> => {
  // I don't see a need to verify the strings. If they match, it'll turnt he
  // feature on. If they don't match, the string will be ignored.
  return features as Array<keyof EditorFeatureMap>;
};

/**
 * Nothing in Editor uses this. It's only sent to the Editor so that it can be
 * sent back to saveData where it appears to be unused.
 *
 * Where Editor sends it to SaveData:
 * https://github.com/HelloFax/hellosign-web/blob/abebfeba344a99cf80554f10d5a4092883100c3b/src/hellospa/page/editor/index.jsx#L344-L361
 *
 * On the BE it's used to populate dateFormat. I didn't find any other use, and
 * I found no uses of it in saveData
 * https://github.com/HelloFax/HelloFax/blob/bb080be60ea0f88bed26040ef7e95b489c8ced76/src/Hello/Editor/EditorActionTrait.php#L671
 *
 */
const convertSignerOptions = (
  _unifiedData: PrepAndSendRequest,
): LegacyEditorData['signerOptions'] => {
  return {};
};

const convertSignerOrder = (
  signers: EditorData['signers'],
): LegacyEditorData['signerOrder'] => {
  const signerOrder = signers.reduce(
    (signerOrder, recipient) => {
      if (recipient.type !== 'fax' && recipient.order != null) {
        signerOrder[recipient.id] = recipient.order;
      }
      return signerOrder;
    },
    {} as NonNullable<LegacyEditorData['signerOrder']>,
  );
  return signerOrder;
};

/**
 * The first signer email address that matches the current account is considered
 * the currentRoleId in the editor, so if there is a PREPARER field, the editor
 * will not ask you to provide a field for currentRoleId
 * @param _unifiedData
 */
const convertCurrentRoleId = (
  unifiedData: PrepAndSendRequest,
): LegacyEditorData['currentRoleId'] => {
  return unifiedData.requestData.editorData.signers.reduce(
    (id, s) => {
      if (id) return id;
      if (s.type === 'signer' && s.email === unifiedData.user?.email) {
        return s.id;
      }
      return id;
    },
    undefined as undefined | string,
  );
};

export const convertCCs = (cc: CCEmail | CCRole): string => {
  if (cc.type === 'ccEmail') {
    if (cc.role && !cc.email) {
      return cc.role.name;
    }
    return cc.email;
  } else if (cc.type === 'ccRole') {
    return cc.name;
  }
  throw new UnreachableError(cc);
};

export const convertUnifiedCCs = (cc: string, id: string): CCEmail | CCRole => {
  if (!HfReactHelper.isValidEmailAddress(cc)) {
    return {
      type: 'ccRole',
      name: cc,
      id: String(id),
    };
  } else {
    return {
      type: 'ccEmail',
      email: cc,
      id: String(id),
    };
  }
};

export const convertUnifiedRules = (rule: EditorData['rules'][0]): Rule => {
  return identity<Rule>({
    ...rule,
    triggers: rule.triggers.map((trigger) => {
      type Trigger = Rule['triggers'][0];
      switch (trigger.operator) {
        case StringArrayOperator.Any:
        case StringArrayOperator.None:
          // Using identity forces type errors to show up here on the return
          // instead of just marking the whole function.
          return identity<Trigger>(trigger);

        case StringOperator.Has:
        case StringOperator.HasNot:
        case StringOperator.Match:
          return identity<Trigger>(trigger);

        case NonNumericOperator.Is:
        case NonNumericOperator.Not:
          return identity<Trigger>(trigger);

        case NumericOperator.Lt:
        case NumericOperator.Gt:
          return identity<Trigger>(trigger);
        default:
      }
      throw new UnreachableError(trigger);
    }),
    actions: rule.actions.map((action) => {
      type Action = Rule['actions'][0];

      switch (action.type) {
        case 'change-field-visibility':
          return identity<Action>({
            ...action,
          });
        case 'change-group-visibility':
          return identity<Action>({
            ...action,
          });
        default:
      }
      throw new UnreachableError(action);
    }),
  });
};

const isMergeField = (
  field: EditorData['mergeFields'][0],
): field is MergeField => {
  // I'm casting this to Field because it will at least persist
  // the `name`, `type` and `x` properties, if they exist.
  // The MergeField type does not contain the `x` property,
  // so I'm using it to determine that this is a MergeField.
  return (field as Field).x === undefined;
};

export const convertMergeField = (
  field: EditorData['mergeFields'][0],
): Field => {
  if (isMergeField(field)) {
    return {
      name: field.name,
      type: field.type === 'text' ? 'text' : 'checkbox',
      integrationMetadata: field.integrationMetadata,
    } as Field;
  } else {
    return convertUnifiedFields(field as EditorData['fields'][0]);
  }
};

export const convertUnifiedFields = (field: EditorData['fields'][0]): Field => {
  switch (field.type) {
    case 'dropdown': {
      // Using identity forces type errors to show up here on the return
      // instead of just marking the whole function.
      return identity<Field>({
        ...field,
        type: 'dropdown',
      });
    }
    case 'radiobutton':
      return identity<Field>({
        ...field,
        type: 'radiobutton',
      });
    case 'text':
      return identity<Field>({
        ...field,
        type: 'text',
        autoFillType: getAutoFillFieldType(field.autoFillType),
      });
    case 'signature':
      return identity<Field>({
        ...field,
        type: 'signature',
      });
    case 'initials':
      return identity<Field>({
        ...field,
        type: 'initials',
      });
    case 'date':
      return identity<Field>({
        ...field,
        type: 'date',
      });
    case 'checkbox':
      return identity<Field>({
        ...field,
        type: 'checkbox',
      });
    case 'hyperlink':
      return identity<Field>({
        ...field,
        type: 'hyperlink',
      });
    default:
  }
  throw new UnreachableError(field);
};

export const convertUnifiedPages = (page: EditorData['pages'][0]): Page => {
  return {
    // cdnSrc: page.cdnSrc,
    src: page.src,
    documentId: page.documentId!,
    height: page.height,
    width: page.width,

    // LegacyEditorData['pages'][][orientation] is 1 | 0
    // but the HelloRequest schema defined it as a number
    orientation: page.orientation === 1 ? 1 : 0,
  };
};

export const makePreparer = (
  user: PrepAndSendRequest['user'],
): LegacyEditorData['preparer'] => {
  return {
    // The old endpoint would send these as null when empty
    company: user.company || null,
    title: user.title || null,

    is_confirmed: user.isConfirmedAccount,
    email: user.email,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    name: user.name || '',
  };
};

export const convertRecipients = (
  recipient: EditorData['signers'][0],
  index: number,
): Signer => {
  const id = recipient.id ?? index + 1;
  switch (recipient.type) {
    case 'fax':
      return {
        id: Number(id),
        email: recipient.fax,
        name: '',
      };
    case 'signer':
      return {
        id: Number(id),
        email: recipient.email,
        name: recipient.name,
        role: recipient.role?.name ?? undefined,
      };
    case 'role':
      return {
        id: Number(id),
        email: null,
        name: recipient.name,
      };
    default:
      throw new UnreachableError(recipient);
  }
};

/* eslint-disable camelcase */

/**
 * TODO: make this type more specific
 */
type EditorDateFormat = string;
export type LegacyEditorData = {
  preparer: {
    is_confirmed: boolean;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    title: null | string;
    company: null | string;
  };
  /**
   * This appears to hold the ID and page numbers starting at 1
   */
  snapshots: {
    [snapshotId: string]: undefined | number[];
  };
  documents: Document[];
  pages: Page[];
  fields: Field[];
  signers: Signer[];

  ccs: string[];
  currentRoleId?: string;
  initialPageRotation: number;
  uploadIntegrations: UploadIntegrations;

  pnsRequestType: RequestType;
  signerOrder: null | Record<Signer['id'], Signer['order']>;
  pdfFields: Array<Field>;
  rules: Rule[];
  /** Maybe not needed */
  signerOptions: unknown;
  mergeFields: Array<Field>;
  options: {
    apiIdsEnabled: boolean;
    isMeOnly: boolean;
    /** embedded */
    shouldRequestSigners: boolean;
    isTemplate: boolean;
    isTemplateLink: boolean;
    permissions: {
      canUseDataValidation: boolean;
      canUseCustomRegexDataValidation: boolean;
    };
    dateFormat: EditorDateFormat | 'default';
    accountDateFormat: string;
    features: Array<keyof EditorFeatureMap>;
    embedded: {
      integrationMetadata: IntegrationMetadata | null;
      isGmail: boolean;
      isDropbox: boolean;
      isEmbeddedRequest: boolean;
      skipMeNow: boolean;
      forceEditSubjectMessage: boolean;
      isEmbeddedTemplate: boolean;
      isCreatedFromTemplate: boolean;
      editedTemplateGuid: null | string;
      parentUrl: null | string;
      redirectUrl: null | string;
      embeddedScript: string;
      subject: null | string;
      message: null | string;
      appName: null | string;
      hideContinueButton: boolean;
      isNewPrepAndSend: boolean;
    };
  };
};

// Coverter back to UnifiedData
export const convertToEditorData = (data: any): EditorData => {
  return {
    pages: data.pages.map((page: Page) => convertPages(page, data.documents)),
    fields: data.fields.map(convertFields),
    mergeFields: data.mergeFields.map(convertMergeField),
    pdfFields: [],
    rules: data.rules,
    ccs: data.ccs.map(convertUnifiedCCs),
    transform: {
      rotate: data.rotate,
    },
    signers: data.signers
      .filter((s: Signer) => s.id !== 'preparer' && s.id !== 'sender')
      .map((s: Signer) => convertSigners(s, data.signerOrder)),
    documents: data.documents.map(convertDocuments),
    editorOptions: {
      allowEditDocuments: true,
      allowEditSigners: true,
      canUseCustomRegexDataValidation: true,
      canUseDataValidation: true,
    },
    signerOptions: {
      fieldOptions: {
        dateFormat: <JSDateFormat>data.dateFormat,
      },
    },
  };
};

const convertDocuments = (document: Document): EditorData['documents'][0] => {
  return {
    id: document.id,
    name: document.name,
    editable: document.editable,
    snapshotGuid: document.snapshotGuid,
  };
};

const convertPages = (page: Page, documents: any): EditorData['pages'][0] => {
  return {
    src: page.src,
    width: page.width,
    height: page.height,
    documentId: page.documentId,
    orientation: page.orientation,
    snapshotGuid: getSnapshotGuid(documents, page.documentId),
    cdnSrc: page.cdnSrc,
  };
};

export const convertFields = (
  field: Field,
):
  | TextField
  | SignatureField
  | InitialsField
  | DateField
  | CheckboxField
  | RadioField
  | DropdownField
  | HyperlinkField => {
  const formattedField = {
    pageIndex: field.pageIndex,
    documentId: field.documentId,
    id: field.id,
    // @ts-ignore
    apiId: field.apiId ?? field.id,
    name: field.name ?? '',
    x: field.x,
    y: field.y,
    width: field.width,
    height: field.height,
    signer: field.signer,
    required: field.required ?? false,
    readOnly: field.readOnly ?? false,
    linkId: field.linkId ?? undefined,
    // @ts-ignore
    isDisabled: field.isDisabled ?? false,
    formLabel: field.formLabel ?? undefined,
  };
  if (
    field.integrationMetadata &&
    // filters out all entries that are undefined
    Object.entries(field.integrationMetadata).filter(
      ([_, value]) => typeof value !== 'undefined',
    ).length > 0
  ) {
    // @ts-ignore
    formattedField.integrationMetadata = { ...field.integrationMetadata };
  }

  switch (field.type) {
    case 'text':
      return identity<TextField>({
        ...formattedField,
        type: 'text',
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.lines ?? field.value ?? undefined,
        autoFillType: getAutoFilledType(field.autoFillType),
        validationType: getValidationType(field.validationType),
        validationCustomRegex: field.validationCustomRegex ?? undefined,
        validationCustomRegexFormatLabel:
          field.validationCustomRegexFormatLabel ?? undefined,
        placeholder: field.placeholder ?? field.name,
        masked: field.masked ?? false,
        lines: field.lines ?? undefined,
      });
    case 'signature':
      return identity<SignatureField>({
        ...formattedField,
        type: 'signature',
        color: <Color>field.color ?? undefined,
        signature: field.signature
          ? <Signature>{
              type: SignatureType.Signature,
              guid: field.signature.guid,
            }
          : undefined,
      });
    case 'initials':
      return identity<InitialsField>({
        ...formattedField,
        type: 'initials',
        color: <Color>field.color ?? undefined,
        signature: field.signature
          ? <Signature>{
              type: SignatureType.Initials,
              guid: field.signature.guid,
            }
          : undefined,
      });
    case 'date':
      return identity<DateField>({
        ...formattedField,
        type: 'date',
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.value ?? null,
        dateFormat:
          <JSDateFormat>field.dateFormat ??
          JSDateFormat.MMDDYYYYSeparatedBySlash,
      });
    case 'checkbox':
      return identity<CheckboxField>({
        ...formattedField,
        type: 'checkbox',
        checked: field.checked ?? undefined,
        requirement: field.requirement ?? undefined,
        groupLabel: field.groupLabel ?? undefined,
        group: field.group ?? undefined,
        groupFormLabel: field.groupFormLabel ?? undefined,
      });
    case 'radiobutton':
      return identity<RadioField>({
        ...formattedField,
        type: 'radiobutton',
        checked: field.checked ?? false,
        requirement: field.requirement ?? '',
        groupLabel: field.groupLabel ?? undefined,
        group: field.group ?? undefined,
        groupFormLabel: field.groupFormLabel ?? undefined,
      });
    case 'dropdown':
      return identity<DropdownField>({
        ...formattedField,
        type: 'dropdown',
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        options: field.options ?? [],
      });
    case 'hyperlink':
      return identity<HyperlinkField>({
        ...formattedField,
        type: 'hyperlink',
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.value ?? null,
        url: field.url ?? '',
      });
    case 'rectangle':
    case 'image':
      throw new Error(`Type ${field.type} is not supported.`);
    default:
      throw new UnreachableError(field);
  }
};

export const convertSigners = (
  signer: Signer,
  signerOrder: any,
): EditorData['signers'][0] | null => {
  if (signer.name && signer.email) {
    let role = null;
    if (signer.role) {
      role = {
        type: 'role',
        id: String(signer.id),
        name: signer.role,
        attachments: [],
        order:
          signerOrder && signerOrder[signer.id] >= 0
            ? signerOrder[signer.id]
            : undefined,
      } as Role;
    }
    return {
      type: 'signer',
      id: String(signer.id),
      name: signer.name,
      email: signer.email,
      role,
      attachments: [],
      order:
        signerOrder && signerOrder[signer.id] >= 0
          ? signerOrder[signer.id]
          : undefined,
    };
  } else {
    return {
      type: 'role',
      id: String(signer.id),
      name: signer.name,
      attachments: [],
      order:
        signerOrder && signerOrder[signer.id] >= 0
          ? signerOrder[signer.id]
          : undefined,
    };
  }
};

const getSnapshotGuid = (documents: Document[], documentId: string): string => {
  const doc = documents.find((d) => d.id === documentId);
  return doc ? doc.snapshotGuid : '';
};

export const getAutoFilledType = (
  autoFillType: AutofillFieldTypes | null | undefined,
): AutoFillType | undefined => {
  switch (autoFillType) {
    case AutofillFieldTypes.FullName:
      return AutoFillType.FullName;
    case AutofillFieldTypes.FirstName:
      return AutoFillType.FirstName;
    case AutofillFieldTypes.LastName:
      return AutoFillType.LastName;
    case AutofillFieldTypes.Email:
      return AutoFillType.Email;
    case AutofillFieldTypes.Title:
      return AutoFillType.Title;
    case AutofillFieldTypes.Company:
      return AutoFillType.Company;
    case null:
    case undefined:
    default:
      return undefined;
  }
};

export const getValidationType = (
  validationType: string | null | undefined,
): ValidationType | undefined => {
  if (
    validationType &&
    Object.values(ValidationType).includes(validationType as ValidationType)
  ) {
    return validationType as ValidationType;
  }
  return undefined;
};
